@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.containermain {
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0px;
  overflow-y: auto;
}

.header {
  font-family: "nunito";
  font-size: 26px;
  font-weight: 400;
}

.overview {
  display: flex;
  flex-direction: column;

  &__card {
    margin: 1rem 0 2rem 0;
    display: flex;
    width: 100%;
    height: 210px;

    // border-radius: 0.4rem;
    // box-shadow: 0 2px 8px rgba(0, 0, 0/ 30);
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  &__cardContent {
    flex: 0 0 75%;
    height: 100%;
    background-color: #fff;
    color: var(--para-color);
    border-radius: 0.5rem 0 0 0.5rem;
    font-size: var(--para-size-large);

    @media screen and (max-width: 600px) {
      font-size: var(--para-size-medium);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    }
  }
  &__cardImage {
    flex: 0 0 25%;
    height: 210px;
    background-color: #fff;
    border-radius: 0 0.5rem 0.5rem 0;
    @media screen and (max-width: 600px) {
      order: -1;
      border-radius: 0.5rem 0.5rem 0 0;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    }

    img {
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      @media screen and (max-width: 600px) {
        border-radius: 0.5rem 0.5rem 0 0;
      }
    }
  }
  .content {
    &__details {
      margin: 2rem 3rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;

      .items {
        display: flex;
        justify-content: space-between;
        font-size: 16px;

        .align_credits {
          @media screen and (max-width: 600px) {
            padding: 0 8.5rem 0 0;
          }
        }
      }
    }
  }

  .btn_color {
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    max-width: 150px;
    padding: 0 1.5rem;
    text-decoration: none;
    height: 35px;
    font-size: 15px;
    border-radius: 4px;

    &:disabled {
      color: #fff;
      background-color: #777;
    }
  }
}

.btn_colorLiveSession {
  background-color: var(--button-background) !important;
  color: var(--button-text) !important;
  border: none;
  &:disabled {
    background-color: #9e9e9e;
    color: var(--button-text) !important;
    border: none;
    cursor: not-allowed;
  }
}
.btn_color:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.tabMargin {
  @media screen and (max-width: 600px) {
    margin: 20rem 0;
  }
}
.align_self {
  align-self: center;
}

.p_text {
  font-size: 15px !important;
  text-align: justify !important;
  padding: 0 1.8rem;
}

.h2_margin {
  margin-top: 1rem !important;
  padding: 1rem 1.8rem;
}

.rupee_size {
  height: 20px !important;
  width: 30px !important;
  fill: #0f80a4;
}
.cls_1,
.cls_2 {
  fill: none;
  stroke: #0f80a4 !important;
  stroke-miterlimit: 10;
  stroke-width: 4.55px;
}
.cls_2 {
  stroke-linecap: round;
}

.clock_size {
  height: 30px !important;
  width: 30px !important;
  // margin-right: 10px;
}
//
.image_container {
  width: 150px;
  height: 100px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ID_container {
  display: flex;
  border-bottom: 1px solid rgb(199, 195, 195);
  padding: 2rem;
  gap: 2rem;
  border-radius: 0.5rem;
}
.details_container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  font-size: 16px !important;
}
.tunits {
  padding: 20px;
  border-bottom: 1px solid rgb(199, 195, 195);
  font-size: 15px;
}
.shadow {
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.6);
  border-radius: 0.5rem;
}
.courses_container {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  width: 60%;
  border: 1px solid lightgrey;
  border-radius: 0.5rem;
}
.pcurriculum {
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  margin-bottom: 3rem;
}

.cls1_s {
  fill: none;
  stroke: #9e9e9e;
  stroke-miterlimit: 10;
  stroke-width: 5.55px;
  stroke-linecap: round;
}
.cls2_s {
  fill: none;
  stroke: #9e9e9e;
  stroke-miterlimit: 10;
  stroke-width: 5.55px;
  stroke-linecap: round;
}

.clock_size__s {
  width: 2rem;
  height: 2rem;
}
.announcementIcon {
  width: 40px;
  height: 40px;
  fill: orangered !important;
  float: left;
  margin-left: 20px;
  margin-top: -10px;
}
.pdfIcon {
  margin-top: -60px;
  float: right;
  cursor: pointer;
}
.announcements {
  border-bottom: 1px;
}
.announcmentDivider {
  color: gray;

  align-self: center;
}

.EventFill {
  fill: #0f80a4;
  height: 2rem;
  width: 2rem;
  margin-left: 0.5rem;
}
.inputLiveSession {
  border: 1px solid rgb(199, 195, 195);
  width: 300px;
  height: 30px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  &:focus {
    outline: none;
  }
}

.containLivesSession {
  border-collapse: separate;
  border-spacing: 1rem;
  tr > :first-child {
    display: flex;
  }
  span {
    color: red;
  }
}

.inputLiveSessionTitle {
  min-width: 200px;
}
.firstViewValidation {
  color: red;
  font-size: 1.35rem;
}
