.maincontainer{
  margin: 0px;
  padding: 30px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.content {
  display: flex;
  background-color: #f2f5f9;
}

.content-view {
  background-color: #f2f5f9;
  margin: 1rem 3rem;
  flex: 1;

  overflow: hidden !important;
  p {
    text-align: justify;
  }
}
.programsHeader {
  color: black !important ;
  margin-bottom: 30px;
  font-weight: bolder !important ;
  margin-bottom: 10px;
}

.card {
  width: 90% !important;
  height: 250px !important;
  margin-left: 0px !important;
}
.imageCard {
  width: 100% !important;
  object-fit: cover !important;
  height: 130px !important;
}
.topicNameIndProg {
  margin-top: 15px !important;
  font-size: 15px !important;
  float: left !important;
  font-weight: bold;
  margin-left: 20px !important;
  margin-right: 20px !important;
  color: #3372b5;
}
.progressbar {
  margin-top: 10px;
  width: 85%;
  margin-left: 18px;
  align-self: center;
}
.clock {
  height: 25px !important;
  width: 25px !important;
  margin-right: 10px;
  margin-top: -4px;
}

.sliderholder{
  width: 100%;
}

.divholder{
  width: 100%;
}
