.reportsAndAnalytics {
  width: 100%;

  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  .PnA-card {
    display: flex;
    flex: 1;
    background-color: var(--bg-card);
    justify-content: space-between;
    padding: 3rem;
    gap: 20rem;
    box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.4);
    border-radius: 0.8rem;
    position: relative;
    &::before {
      content: '';
      height: 80%;
      width: 1px;
      background-color: #777;
      position: absolute;
      top: 14px;
      right: 52%;
    }
    .programs {
      display: flex;
      flex-wrap: wrap;
      .inProgressNComp {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6rem;
        h1 {
          text-align: center;
          margin-bottom: 1rem;
          color: var(--header-color);
        }
        p {
          font-size: var(--para-size-large);
          @media screen and (min-width: 1280px) and (max-width: 1439px) {
            font-size: var(--para-size-medium);
          }
          @media screen and (min-width: 900px) and (max-width: 1279px) {
            font-size: var(--para-size-small);
          }
        }
      }
    }

    .Assignments {
      display: flex;
      .submittedNPastDLine {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6rem;
        h1 {
          text-align: center;
          margin-bottom: 1rem;
          color: var(--header-color);
        }
        p {
          font-size: var(--para-size-large);

          @media screen and (min-width: 1280px) and (max-width: 1439px) {
            font-size: var(--para-size-medium);
          }
          @media screen and (min-width: 900px) and (max-width: 1279px) {
            font-size: var(--para-size-small);
          }
        }
      }
    }
  }

  .assessmentAnalysis {
    padding: 3rem;
    .analysis-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 3rem;
      p {
        font-size: var(--para-size-small);
      }
      .square {
        height: 10px;
        width: 10px;
      }
      .col--red {
        background-color: red;
      }
      .col--yellow {
        background-color: yellow;
      }
      .col--green {
        background-color: green;
      }
      .flex {
        display: flex;
        padding: 1rem;
        p {
          padding: 0;
        }
      }
    }

    .analysis-container {
      height: 55vh;
      width: 100%;
      background-color: var(--bg-card);
      border-radius: 0.8rem;
      box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.4);
    }
  }
}
