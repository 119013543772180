.program {
  display: flex;
  border-radius: 5px;
  box-shadow: 0 0 11px rgba($color: #000000, $alpha: 0.3);
  margin: 1rem;
  margin-top: 50px;

  &__img {
    flex: 0 0 24%;
    background-color: aqua;
    border-radius: 5px 0 0 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__details {
    flex: 1;
    background-color: #fff;
    padding: 2rem;
    border-radius: 0 5px 5px 0;
    .h1 {
      text-align: left;
    }
  }
  .individual-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 70%;
    margin-bottom: 1rem;

    p:last-child {
      text-align: left;
    }
  }
  .program-info {
    flex: 0 0 70%;
    align-items: center;
    padding-right: 2rem;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 30%;
      height: 100%;
      width: 0.5px;
      background-color: #01498e;

      transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
        background-color 0.1s;
    }
    & .individual-info:last-child {
      margin-top: 1rem;
    }
  }
  .time-info {
    flex: 1;
    justify-content: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: var(--para-size-small);
  }
  .program_info__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    // width:90%;
    margin-left: 20px;
  }
  .width {
    width: 65%;
    margin-top: 1rem;
  }
}

.overviewIcon {
  width: 60px !important;
  height: 60px !important;
  fill: orange !important;
}

.overviewIconDis {
  width: 60px !important;
  height: 60px !important;
  fill: gray !important;
}

.approvedIcon {
  width: 70px !important;
  height: 70px !important;
  fill: green !important;
}

.approvedIconDis {
  width: 70px !important;
  height: 70px !important;
  fill: gray !important;
}
.fillYourDetailsIcon {
  width: 80px;
  height: 80px;
  fill: blue !important;
}
.fillYourDetailsIconDis {
  width: 80px;
  height: 80px;
  fill: black !important;
}
.underReviewIcon {
  width: 25px !important;
  height: 25px !important;
  fill: orangered !important;
}
.hr_line {
  width: 50%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid #000;
}
