.subHeader{
    font-size: 26px;
    color: #E77C2D;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: 10px 0px;
    margin: 0;
    text-align:left;
   
}
.contentStyle{
    color: #000;
    padding: 15px 0px;
    text-align: justify;
 
    font-size: var(--para-size-large);
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}
.listPoints{
    color: #000;
    text-align: justify;
font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
font-size: var(--para-size-large);
}
li::before{
    content: icon;
}