html {
  box-sizing: border-box;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  overflow: hidden !important;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
body {
  background-color: #f2f5f9 !important;
}
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1,
h2 {
  /* font-size: 100%; */
  font-weight: normal;
}

h3 {
  /* font-size: 100%; */
  font-weight: normal;
}
h4,
h5,
h6 {
  /* font-size: 100%; */
  font-weight: normal;
}
ul {
  list-style: none;
}
:root {
  -moz-tab-size: 8;
  tab-size: 8;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
hr {
  height: 0;
}
abbr[title] {
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
  top: -0.5em;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

img,
embed,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

.breadcrumb {
  background-color: transparent !important;
  padding: 0.75rem 0 !important;
  font-size: var(--para-size-medium) !important;
  margin-bottom: 2rem !important;
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: var(--button-background) !important;
  font-weight: bolder;
  width: 124px;
  height: 32px;
  font-size: 13px !important;
}
